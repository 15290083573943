/**
 * @file It contains scripts for logout view
 */
import { mapActions } from "vuex"

export default {
  name: "Logout",
  data() {
    return {}
  },
  async created() {
    await this.logout()
    this.$router.push({ name: "login" })
  },
  methods: {
    ...mapActions({
      "logout": "auth/logout"
    })
  }
}